import React from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { ArrastrarNa } from "./ArrastrarNa";
import { ArrastrarNo } from "./ArrastrarNo";
import { Tooltip } from "flowbite-react";
import { Link } from "react-router-dom";

export function IdentJ1() {
  return (
    <div className="flex flex-col justify-center items-center w-full px-12">
      <Tooltip
        content="Ri nuq’uu’ jay ri ke’ela wi."
        className="bg-lime-800"
        arrow={false}
      >
        <h1 className="text-3xl my-8 text-center">
          Mi indumentaria y lo que significa
        </h1>
      </Tooltip>
      <div className="grid grid-cols-2 w-full gap-8">
        <div className="flex flex-row items-center">
          <DndProvider options={HTML5toTouch}>
            <ArrastrarNa />
          </DndProvider>
        </div>
        <div className="flex flex-row items-center">
          <DndProvider options={HTML5toTouch}>
            <ArrastrarNo />
          </DndProvider>
        </div>
      </div>
      <Link
        to="/identidad"
        className="mt-3 focus:outline-none text-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        <svg fill="currentColor" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zm-4.5-.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
          />
        </svg>
        Regresar
      </Link>
    </div>
  );
}
