import "./nameadd.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "flowbite-react";

export function NameAdd(props) {
  const [nombre, setNombre] = useState("");
  const navigate = useNavigate();

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.actualizarNombre(nombre);
    navigate("/menu");
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="">
        <Tooltip
          content="Kinetz’anik jay kinawik"
          className="bg-lime-800"
          arrow={false}
        >
          <h1 className="text-center mb-10 text-5xl my-8 font-titan tracking-wider">
            Juego y Aprendo
          </h1>
        </Tooltip>
      </div>

      <div className="flex flex-row justify-around">
        <div className="basis-1/4 pl-10">
          <img src="./img/nina.png" />
        </div>
        <form
          className="flex basis-1/2 flex-col gap-8 justify-center items-center"
          onSubmit={handleSubmit}
          action="/menu"
        >
          <input
            value={nombre}
            onChange={handleNombreChange}
            className="inputName"
            name="name"
            type="text"
            placeholder="B'i / Nombre"
          ></input>
          <Tooltip content="Okeem" className="bg-lime-800" arrow={false}>
            <button className="buttonName" type="submit">
              Entrar
            </button>
          </Tooltip>
        </form>
        <div className="basis-1/4 pr-10">
          <img src="./img/nino.png" />
        </div>
      </div>
    </div>
  );
}
