import React, { useState } from "react";
import Globos from "./Globos";
import { useDrop } from "react-dnd";
import nino from "../img/nino.png";

const DerechosH = [
  {
    id: 1,

    img: "/img/dhj1/dh1.png",
  },
  {
    id: 2,

    img: "/img/dhj1/dh2.png",
  },
  {
    id: 3,

    img: "/img/dhj1/dh3.png",
  },
  {
    id: 4,

    img: "/img/dhj1/dh4.png",
  },
  {
    id: 5,

    img: "/img/dhj1/dh5.png",
  },
  {
    id: 6,

    img: "/img/dhj1/dh6.png",
  },
];

const ListaGlobos = [
  {
    id: 1,
    url: "/img/dhj1/d1.png",
  },
  {
    id: 2,
    url: "/img/dhj1/d2.png",
  },
  {
    id: 3,
    url: "/img/dhj1/d3.png",
  },
  {
    id: 4,
    url: "/img/dhj1/d4.png",
  },
  {
    id: 5,
    url: "/img/dhj1/d5.png",
  },
  {
    id: 6,
    url: "/img/dhj1/d6.png",
  },
];

export default function Arrastrar(props) {
  const [board, setBoard] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "image",
    drop: (item) => addImageToboard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToboard = (id) => {
    const globosListAdd = ListaGlobos.filter((picture) => id === picture.id);
    setBoard((board) => [...board, globosListAdd[0]]);

    props.mostrarTarjeta(DerechosH.filter((derecho) => id === derecho.id));
  };

  return (
    <>
      <div className="flex flex-col gap-6 justify-center items-center">
        <div className="flex flex-row opacity-70">
          {ListaGlobos.map((globo) => {
            return <Globos url={globo.url} id={globo.id} key={globo.id} />;
          })}
        </div>
        <img className="h-64 scale-110" src="/img/nino.png" alt="" />
        <div className="border-2 flex flex-row min-h-[7rem] w-full" ref={drop}>
          {board.map((globo) => {
            return <Globos url={globo.url} id={globo.id} key={globo.id} />;
          })}
        </div>
      </div>
    </>
  );
}
