export const imgs = [
  {
    id: 1,
    img: "/img/dhj2/01-dv1.png",
    alt: "Derecho a la vida",
  },
  {
    id: 2,
    img: "/img/dhj2/01-dv2.png",
    alt: "Derecho a la vida 2",
  },
  {
    id: 3,
    img: "/img/dhj2/02-ds1.png",
    alt: "Derecho a la salud",
  },
  {
    id: 4,
    img: "/img/dhj2/02-ds2.png",
    alt: "Derecho a la salud 2",
  },
  {
    id: 5,
    img: "/img/dhj2/03-de1.png",
    alt: "Derecho a la educación",
  },
  {
    id: 6,
    img: "/img/dhj2/03-de2.png",
    alt: "Derecho a la educación 2",
  },
  {
    id: 7,
    img: "/img/dhj2/04-dic1.png",
    alt: "Derecho a la identidad cultural",
  },
  {
    id: 8,
    img: "/img/dhj2/04-dic2.png",
    alt: "Derecho a la identidad cultural 2",
  },
  {
    id: 9,
    img: "/img/dhj2/05-drm1.png",
    alt: "Derecho a reunión y manifestación",
  },
  {
    id: 10,
    img: "/img/dhj2/05-drm2.png",
    alt: "Derecho a reunión y manifestación 2",
  },
  {
    id: 11,
    img: "/img/dhj2/06-dvi1.png",
    alt: "Derecho a la vivienda digna",
  },
  {
    id: 12,
    img: "/img/dhj2/06-dvi2.png",
    alt: "Derecho a la vivienda digna 2",
  },
];
/* const images = [
    "01-dv1.png",
    "01-dv2.png",
    "02-ds1.png",
    "02-ds2.png",
    "03-de1.png",
    "03-de2.png",
    "04-dic1.png",
    "04-dic2.png",
    "05-drm1.png",
    "05-drm2.png",
    "06-dvi1.png",
    "06-dvi2.png",
  ]; */

//const newImages = images.slice(0, size);

/*  return images.sort(() => Math.random() - 0.5); */

/* return newImages.flatMap(item => [`1|${item}`, `2|${item}`])
          .sort(() => Math.random() - 0.5) */
// 50% 0 - 0.5   0.4 - 0.5 -
// 50% 0.5 - 1   0.7 - 0.5 +
