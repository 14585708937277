import React, { useState } from "react";
import Elementos from "./Elementos";
import { useDrop } from "react-dnd";

const ListaPartes = [
  {
    id: 1,

    url: "/img/idj1/ido1.png",
  },
  {
    id: 2,

    url: "/img/idj1/ido2.png",
  },
  {
    id: 3,

    url: "/img/idj1/ido3.png",
  },
];

const ListaElementos = [
  {
    id: 1,
    url: "/img/idj1/id1a.png",
  },
  {
    id: 2,
    url: "/img/idj1/id2b.png",
  },
  {
    id: 3,
    url: "/img/idj1/id3c.png",
  },
];

export function ArrastrarNo(props) {
  const [board, setBoard] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "image",
    drop: (item) => addImageToboard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToboard = (id) => {
    const globosListAdd = ListaPartes.filter((picture) => id === picture.id);
    setBoard((board) => [...board, globosListAdd[0]]);
  };

  return (
    <div className="flex flex-row-reverse w-full gap-6">
      <div className="flex flex-col basis-1/3 items-center">
        {ListaElementos.map((globo) => {
          return <Elementos url={globo.url} id={globo.id} key={globo.id} />;
        })}
      </div>
      <div className="border-2 w-full relative" ref={drop}>
        {board.map((globo) => {
          return (
            <img
              src={globo.url}
              className="absolute max-h-full"
              key={globo.id}
            />
          );
        })}
      </div>
    </div>
  );
}
