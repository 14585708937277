import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./puzzle.css";

import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";

import { Rompecabeza } from "./Rompecabeza";
import { Tooltip } from "flowbite-react";

export function IdentJ2() {
  const [mostrar, setMostrar] = useState(false);

  return (
    <div className="flex flex-col h-full justify-center items-center">
      <Tooltip
        content="Kinwilo, kinetz’anik, kinch’ob’onik jay
    kintz’aqanik"
        className="bg-lime-800"
        arrow={false}
      >
        <h1 className="text-3xl my-8 text-center">
          Mi conexión con la Madre Tierra y mi palabra
        </h1>
      </Tooltip>
      <div className="flex flex-row h-5/6 items-center mx-6">
        {/* <div className="basis-1/3 items-center justify-center">
          {mostrar ? (
            <>
              <img src="../img/mh/lugar1.png" className="w-80" />
              <img src="../img/mh/lugar2.png" className="w-80" />
              <img src="../img/mh/lugar3.png" className="w-80" />
            </>
          ) : (
            <img src="../img/nino.png" />
          )}
        </div> */}
        <div className="">
          <Rompecabeza terminado={setMostrar} />
        </div>
        {/*  <div className="basis-1/3">
          <img src="../img/nina.png"></img>
        </div> */}
      </div>
      <Link
        to="/identidad"
        className="mt-3 focus:outline-none text-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        <svg fill="currentColor" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zm-4.5-.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
          />
        </svg>
        Regresar
      </Link>
    </div>
  );
}
