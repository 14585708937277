import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "./DhJ1.css";

import Arrastrar from "./componentsj1/Arrastrar";
import { Tooltip } from "flowbite-react";

import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";

export function DhJ1() {
  const [tarjeta, setTarjeta] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const setearTarjeta = (tarjetaNueva) => {
    setTarjeta((tarjeta) => [...tarjeta, tarjetaNueva[0]]);

    const existe = tarjeta.find((objeto) => objeto.id === tarjetaNueva.id);
  };

  const openLightbox = (index) => {
    setSelectedImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <Tooltip
        content="Kinwilo, kinwilo jay kinch’ob’o"
        className="bg-lime-800"
        arrow={false}
      >
        <h1 className="text-center text-3xl my-8">Veo, veo e imagino</h1>
      </Tooltip>
      <div className="grid grid-cols-2 gap-10 px-5">
        <div className="grid grid-cols-2 gap-4">
          {tarjeta.map((image, index) => (
            <img
              key={index}
              src={image.img}
              alt={`Image ${index + 1}`}
              onClick={() => openLightbox(index)}
              className="w-52 rounded-xl mb-3 hover:shadow-lg hover:cursor-pointer"
            />
          ))}

          {lightboxOpen && (
            <div className="lightbox" onClick={closeLightbox}>
              <div
                className="lightbox-content"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={tarjeta[selectedImage].img}
                  alt={`Image ${selectedImage + 1}`}
                />
              </div>
            </div>
          )}
        </div>

        <div className="w-full">
          <DndProvider options={HTML5toTouch}>
            <Arrastrar mostrarTarjeta={setearTarjeta} />
          </DndProvider>
        </div>
      </div>
      <Link
        to="/ddhh"
        className="mt-3 focus:outline-none text-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        <svg fill="currentColor" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zm-4.5-.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
          />
        </svg>
        Regresar
      </Link>
    </div>
  );
}
