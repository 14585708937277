import { JigsawPuzzle } from "react-jigsaw-puzzle/lib";
//import img from "/img/";

export function Rompecabeza(props) {
  const set = () => {
    props.terminado(true);
  };
  return (
    <JigsawPuzzle
      imageSrc={"/img/idJ2.png"}
      rows={3}
      columns={3}
      onSolved={set}
      className="jigsaw-puzzle"
    />
  );
}
