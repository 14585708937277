import "./App.css";

import { CardsEjes } from "./components/home/CardsEjes";
import { IdentJ1 } from "./components/identidad/juego1/IdentJ1";
import { MenuDh } from "./components/dh/MenuDh";
import { MenuId } from "./components/identidad/MenuId";
import { MenuMh } from "./components/mh/MenuMh";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DhJ1 } from "./components/dh/juego1/DhJ1";
import { DhJ2 } from "./components/dh/juego2/DhJ2";
import { MhJ1 } from "./components/mh/juego1/MhJ1";
import { NameAdd } from "./components/home/NameAdd";
import React, { useState } from "react";
import { IdentJ2 } from "./components/identidad/juego2/IdentJ2";
import { MhJ2 } from "./components/mh/juego2/MhJ2";

function App() {
  const [nombre, setNombre] = useState("");

  const setearNombre = (vieneNombre) => {
    setNombre(vieneNombre);
  };
  document.title = "Juego y Aprendo";

  return (
    <div
      className="bg-green-600 flex justify-center items-center w-screen h-screen bg-imgf bg-no-repeat bg-cover bg-fixed bg-right"
      id="App"
    >
      <div className="bg-orange-500 border-solid border-white border-2 flex justify-center items-center rounded-3xl w-3/4 h-auto m-10 min-h-[80%] max-h-fit shadow-2xl text-white">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<NameAdd actualizarNombre={setearNombre} />}
            />

            <Route path="/menu" element={<CardsEjes name={nombre} />} />
            <Route path="/ddhh" element={<MenuDh />} />
            <Route path="/mh" element={<MenuMh />} />
            <Route path="/mh/j1" element={<MhJ1 />} />
            <Route path="/mh/j2" element={<MhJ2 />} />
            <Route path="/identidad" element={<MenuId />} />
            <Route path="/identidad/j1" element={<IdentJ1 />} />
            <Route path="/identidad/j2" element={<IdentJ2 />} />
            <Route path="/ddhh/j1" element={<DhJ1 />} />
            <Route path="/ddhh/j2" element={<DhJ2 />} />
            <Route path="/mh/j1" element={<MhJ1 />} />
            <Route path="/*" element={<CardsEjes />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
