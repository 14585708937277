import { JigsawPuzzle } from "react-jigsaw-puzzle/lib";

export function Rompecabeza(props) {
  const set = () => {
    props.terminado(true);
  };
  return (
    <JigsawPuzzle
      imageSrc="https://www.aprendo.museodelamemoriarabinal.org/img/mh/mapa.png"
      rows={3}
      columns={2}
      onSolved={set}
      className="jigsaw-puzzle"
    />
  );
}
