import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "flowbite-react";

export function MenuId() {
  return (
    <div className="flex flex-col h-full justify-center items-center">
      <Tooltip
        content="K’exwachiil ano’nib’al"
        className="bg-lime-800"
        arrow={false}
      >
        <h1 className="text-3xl mb-14">Identidad Cultural</h1>
      </Tooltip>
      <div className="flex flex-row justify-center items-center py-8 gap-14">
        <Tooltip
          content="Kinwilo, kinwilo jay kinch’ob’o"
          className="bg-lime-800"
          arrow={false}
        >
          <Link className="buttonName" to="/identidad/j1">
            <img src="../img/btnId1.png" className="w-48" />
            <div className="p-2 w-48">Mi indumentaria y lo que significa</div>
          </Link>
        </Tooltip>
        <Tooltip
          content="Ri uriqik ri k’uleel"
          className="bg-lime-800"
          arrow={false}
        >
          <Link className="buttonName" to="/identidad/j2">
            <img src="../img/btnId2.png" className="" />
            <div className="p-2 w-48">
              Mi conexión con la Madre Tierra y mi palabra
            </div>
          </Link>
        </Tooltip>
      </div>
      <Link
        to="/menu"
        className="focus:outline-none text-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        <svg fill="currentColor" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zm-4.5-.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
          />
        </svg>
        Regresar
      </Link>
    </div>
  );
}
