import { Link } from "react-router-dom";
import { Tooltip } from "flowbite-react";

export function CardsEjes(props) {
  return (
    <div className="flex flex-col h-full justify-center items-center">
      <Tooltip content="Sa' a'anoom" className="bg-lime-800" arrow={false}>
        <h1 className="text-5xl text-center mb-8">Hola {props.name}</h1>
      </Tooltip>

      <Tooltip
        content="¿kwaj ri etz’aneem?"
        className="bg-lime-800"
        arrow={false}
      >
        <h1 className="text-3xl">¿Qué quieres jugar?</h1>
      </Tooltip>
      <div className="grid grid-cols-3 justify-center items-center py-8 gap-14 w-4/5">
        <Tooltip
          content="Kooch Loq’oxiik ke ri tikaweex"
          className="bg-lime-800"
          arrow={false}
        >
          <Link className="buttonName flex flex-col" to="/ddhh">
            <img src="./img/dd.png" className="imgCards" />
            <div className="cardTitle">Derechos Humanos</div>
          </Link>
        </Tooltip>
        <Tooltip
          content="Na’ojib’al chwi ri anoj tziij"
          className="bg-lime-800"
          arrow={false}
        >
          <Link className="card buttonName" to="/mh">
            <img src="./img/memoriah.png" className="imgCards" />
            <div className="cardTitle">Memoria Histórica</div>
          </Link>
        </Tooltip>
        <Tooltip
          content="K’exwachiil ano’nib’al"
          className="bg-lime-800"
          arrow={false}
        >
          <Link className="card buttonName" to="/identidad">
            <img src="./img/identidad.png" className="imgCards" />
            <div className="cardTitle">Identidad Cultural</div>
          </Link>
        </Tooltip>
      </div>
      <Link
        to="/"
        className="mt-3 focus:outline-none text-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        <svg viewBox="0 0 1024 1024" fill="currentColor">
          <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
          <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>
        Salir
      </Link>
    </div>
  );
}
